<template>
  <div id="section2">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <h1  class="text-left">
            <div v-for="(item, idx) in payTitulos" :key="idx">
            {{item}}
            </div>
          </h1>
          <br />
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulos":[
      "Forma parte de nuestra red de socios. Juntos llegamos más lejos. "
    ]
  },
  "es":{
    "payTitulos":[
      "Forma parte de nuestra red de socios. Juntos llegamos más lejos."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    payTitulos() {
      return this.$t("payTitulos");
    }
  }
};
</script>
