<template>
  <div id="section3">
    <div class="fondoGrisClaro" >
      <b-container fluid>
        <b-container class="text-center">
          <br />
          <h2 class="text-left">
            {{$t("payTitulos")}}
          </h2>
          <p class="text-left">
            {{$t("payText")}}
          </p>
          <br />
          <b-form>
            <b-row>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-nombre" 
                  v-model="form.nombre" required
                  :placeholder="$t('payNombre')"/>
              </b-col>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-cargo" 
                  v-model="form.cargo" 
                  :placeholder="$t('payCargo')"/>
              </b-col> 
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-telef" 
                  v-model="form.telef" 
                  :placeholder="$t('payTelef')"/>
              </b-col>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-email" 
                  v-model="form.email" required
                  :placeholder="$t('payEmail')"/>
              </b-col>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-empresa" 
                  v-model="form.empresa" 
                  :placeholder="$t('payEmpresa')"/>
              </b-col>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-input id="id-url" 
                  v-model="form.url" required
                  :placeholder="$t('payUrl')"/>
              </b-col>
              <b-col sm="12" class="pb-2">
                <b-form-input id="id-descripcion" 
                  v-model="form.descripcion" 
                  :placeholder="$t('payDesc')"/>
              </b-col>
              <b-col md="6" sm="12" class="pb-2 text-left">
                {{$t("payTipoP")}}
                <b-form-checkbox-group id="id-tipop"
                  v-model="form.tipo"
                  :options="payTipo"
                  name="tipop"
                  stacked
                />
              </b-col>
              <b-col md="6" sm="12" class="pb-2">
                <b-form-select id="id-pais"
                v-model="form.pais"
                :options="listPais"
                />
              </b-col>
              <b-col sm="12" class="pb-2">
                <b-form-textarea id="id-ocom" 
                  v-model="form.comentario" 
                  :placeholder="$t('payOCom')"/>
              </b-col>
              <b-col sm="12" class="pb-2 text-left">
                {{$t("payOtra")}}
                <b-form-radio-group id="id-conocido" 
                  v-model="form.conocido" 
                  :options="payConocido"
                  name="otrar"
                  stacked
                  />
                  <b-form-input id="id-otro"
                  v-model="form.otro"
                  v-show="payConocido[form.conocido] == 'Otro'"
                  />
              </b-col>
              <b-col sm="12" class="pb-2 text-left">
                <b-form-checkbox id="id-acept" 
                  v-model="form.acept" 
                  >
                  {{$t("payAcept")}}
                  </b-form-checkbox>
              </b-col>
              <b-col sm="12" class="pb-2 text-left">
                <b-button @click="enviarFormulario()">
                  {{$t("payBtn")}}
                </b-button>
              </b-col>
            </b-row>
          <br />
          </b-form>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulos": "Coméntanos tus necesidades.",
    "payText": "Te ofrecemos todas nuestras soluciones a tu medida. Ponte en contacto con nosotros y hablamos."
    , "payNombre": "Name and Lastname *"
    , "payCargo": "Cargo"
    , "payTelef": "Teléfono"
    , "payEmail": "Email de contacto *"
    , "payEmpresa": "Nombre de la empresa *"
    , "payUrl": "Url de la empresa"
    , "payDesc": "Descripción de la empresa"
    , "payPais": "Country"
    , "payTipoP": "¿Qué tipo de partner es?"
    , "payOCom": "Otros comentarios"
    , "payOtra": "¿Cómo nos has conocido?"
    , "payAcept": "Accedo a recibir comunicaciones de productos de AICAD y servicios relacionados que puedan ser de mi interés (podrás darte de baja en cualquier momento)."
    , "payBtn": "ENVIAR"
    , "payTipo": [
      "Online - Proveedor de servicios de pago"
      , "Online - Carro de la compra Online"
      , "Online - Agencia y desarrollo web"
      , "Online - Agencia y desarrollo móvil"
      , "Presencial - Proveedor de servicios de pago"
      , "Presencial - Integrador de sistemas integrados"
      , "Otro"
    ]
    , "payConocido": [
      "En un evento"
      , "Recomendación de un amigo o conocido"
      , "Buscando en internet"
      , "Banner / publicidad online"
      , "Publicidad impresa"
      , "A través de mi sucursal bancaria"
      , "Otro"
    ]
  },
  "es":{
    "payTitulos": "Coméntanos tus necesidades.",
    "payText": "Te ofrecemos todas nuestras soluciones a tu medida. Ponte en contacto con nosotros y hablamos."
    , "payNombre": "Nombres y Apellidos *"
    , "payCargo": "Cargo"
    , "payTelef": "Teléfono"
    , "payEmail": "Email de contacto *"
    , "payEmpresa": "Nombre de la empresa *"
    , "payUrl": "Url de la empresa"
    , "payDesc": "Descripción de la empresa"
    , "payPais": "Pais"
    , "payTipoP": "¿Qué tipo de partner es?"
    , "payOCom": "Otros comentarios"
    , "payOtra": "¿Cómo nos has conocido?"
    , "payAcept": "Accedo a recibir comunicaciones de productos de AICAD y servicios relacionados que puedan ser de mi interés (podrás darte de baja en cualquier momento)."
    , "payBtn": "ENVIAR"
    , "payTipo": [
      "Online - Proveedor de servicios de pago"
      , "Online - Carro de la compra Online"
      , "Online - Agencia y desarrollo web"
      , "Online - Agencia y desarrollo móvil"
      , "Presencial - Proveedor de servicios de pago"
      , "Presencial - Integrador de sistemas integrados"
      , "Otro"
    ]
    , "payConocido": [
      "En un evento"
      , "Recomendación de un amigo o conocido"
      , "Buscando en internet"
      , "Banner / publicidad online"
      , "Publicidad impresa"
      , "A través de mi sucursal bancaria"
      , "Otro"
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      form: {
        nombre: ""
        , cargo: ""
        , telef: ""
        , email: ""
        , empresa: ""
        , url: ""
        , descripcion: ""
        , pais: null
        , comentario: ""
        , otra: ""
        , tipo: []
        , conocido: ""
        , acept: null
      },
      listPais: [{ value: null, text: this.$t('payPais') },"España","Afghanistan","Albania","Algeria","American Samoa","Andorra"
      ,"Angola","Antigua and Barbuda","Argentina","Armenia","Australia","Austria"
      ,"Azerbaijan","Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium"
      ,"Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia and Herzegovina","Botswana"
      ,"Brazil","Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon"
      ,"Canada","Cape Verde","Islas Caimán","Central African Republic","Chad","Chile"
      ,"China","Colombia","Comoros","Congo, Democratic Republic of the","Congo, Republic of the"
      ,"Costa Rica","Costa de Marfil","Croatia","Cuba","Cyprus","Czech Republic","Denmark"
      ,"Djibouti","Dominica","Dominican Republic","East Timor","Ecuador","Egypt","El Salvador"
      ,"Equatorial Guinea","Eritrea","Estonia","Ethiopia","Islas Faroe","Fiji","Finland","France"
      ,"Polinesia Francesa","Gabon","Gambia","Georgia","Germany","Ghana","Greece","Greenland"
      ,"Grenada","Guam","Guatemala","Guinea","Guinea-Bissau","Guyana","Haiti","Honduras","Hong Kong"
      ,"Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Israel","Italy","Jamaica"
      ,"Japan","Jordan","Kazakhstan","Kenya","Kiribati","North Korea","South Korea","Kosovo","Kuwait"
      ,"Kyrgyzstan","Laos","Latvia","Lebanon","Lesotho","Liberia","Libya","Liechtenstein","Lithuania"
      ,"Luxembourg","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta"
      ,"Marshall Islands","Mauritania","Mauritius","Mexico","Micronesia","Moldova","Monaco"
      ,"Mongolia","Montenegro","Morocco","Mozambique","Myanmar","Namibia","Nauru","Nepal"
      ,"Netherlands","New Zealand","Nicaragua","Niger","Nigeria","Northern Mariana Islands"
      ,"Norway","Oman","Pakistan","Palau","Palestine, Estado de","Panama","Papua New Guinea"
      ,"Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Romania"
      ,"Russia","Rwanda","Saint Kitts and Nevis","Saint Lucia","Saint Vincent and the Grenadines"
      ,"Samoa","San Marino","Sao Tome and Principe","Saudi Arabia","Senegal","Serbia","Seychelles"
      ,"Sierra Leone","Singapore","Sint Maarten","Slovakia","Slovenia","Solomon Islands","Somalia"
      ,"South Africa","Sri Lanka","Sudan","Sudan, South","Suriname","Swaziland","Sweden","Switzerland"
      ,"Syria","Taiwan","Tajikistan","Tanzania","Thailand","Togo","Tonga","Trinidad and Tobago","Tunisia"
      ,"Turkey","Turkmenistan","Tuvalu","Uganda","Ukraine","United Arab Emirates","United Kingdom"
      ,"United States","Uruguay","Uzbekistan","Vanuatu","Vatican City","Venezuela","Vietnam"
      ,"Virgin Islands, British","Virgin Islands, U.S.","Yemen","Zambia","Zimbabwe"]
    };
  },
  methods: {
    enviarFormulario(){
      console.log(this.form);
      let variant = 'default';
      this.$bvToast.toast('Enviado formulario ... ', {
          title: this.$t('message.pay.page3.1'),
          variant: variant,
          autoHideDelay: 3000,
          solid: true
        });
      this.form = {
        nombre: ""
        , cargo: ""
        , telef: ""
        , email: ""
        , empresa: ""
        , url: ""
        , descripcion: ""
        , pais: null
        , comentario: ""
        , otra: ""
        , tipo: []
        , conocido: ""
        , acept: null
      }  
    }
  },
  computed: {
    payTipo() {
      return this.$t("payTipo");
    },
    payConocido() {
      return this.$t("payConocido");
    }
  }
};
</script>
